angular.module('app.controllers').controller('BestPracticeCtrl', ['$scope', '$rootScope', '$timeout', '$cookies', '$location', 'APIBestPracticeData'].append(function(self, root, $timeout, $cookies, $location, APIBestPracticeData) {
  self.comingSoonItems = null;
  self.items = null;
  APIBestPracticeData.query({
    slug: 'best-practice-soon'
  }, function(data) {
    return self.comingSoonItems = data.filter(function(item) {
      return item.visible;
    });
  }, function(error) {
    return console.log(error);
  });
  return APIBestPracticeData.query({
    slug: 'best-practice'
  }, function(data) {
    return self.items = data.filter(function(item) {
      return item.visible;
    });
  }, function(error) {
    return console.log(error);
  });
}));

angular.module('app.controllers').controller('BestPracticeDetailsCtrl', ['$scope', '$rootScope', '$timeout', '$cookies', '$location', 'APIBestPracticeData'].append(function(self, root, $timeout, $cookies, $location, APIBestPracticeData) {
  self.slug = "details-" + self.get('url');
  self.isLoading = true;
  return APIBestPracticeData.get({
    slug: self.slug
  }, function(data) {
    self.isLoading = false;
    return self.data = data;
  }, function(error) {
    console.log(error);
    return self.isLoading = false;
  });
}));
