angular.module('app.controllers').controller 'BestPracticeCtrl', [
  '$scope', '$rootScope', '$timeout', '$cookies','$location', 'APIBestPracticeData'
].append (self, root, $timeout, $cookies,  $location, APIBestPracticeData) ->

  self.comingSoonItems = null;
  self.items = null;

  APIBestPracticeData.query {slug: 'best-practice-soon'}, (data) ->
    self.comingSoonItems = data.filter (item) ->
      return item.visible
  , (error) ->
      console.log error

  APIBestPracticeData.query {slug: 'best-practice'}, (data) ->
    self.items = data.filter (item) ->
      return item.visible
  , (error) ->
      console.log error


angular.module('app.controllers').controller 'BestPracticeDetailsCtrl', [
  '$scope', '$rootScope', '$timeout', '$cookies','$location', 'APIBestPracticeData'
].append (self, root, $timeout, $cookies,  $location,  APIBestPracticeData) ->

  self.slug =  "details-" + self.get('url')
  self.isLoading = true;
  APIBestPracticeData.get {slug: self.slug}, (data) ->
    self.isLoading = false;
    self.data = data;

  , (error) ->
    console.log error
    self.isLoading = false;


